import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import "bootstrap/dist/css/bootstrap.min.css"
import Folim from "../img/folder-symlink.svg"
import { Container, Nav, Card, CardImg, CardProps, CardImgProps } from "react-bootstrap"

const PartsPage = () => (
    <Layout>
        <Container>
            <br /><br />
            <h1 class="text-center">ДОКУМЕНТЫ</h1>
            <br />
            <div class="d-grid gap-2 col-6 mx-auto text-center">
                <div>
                    <a className="navbar-item text-center background-dark-gray-ozdo" activeClassName="callout" href="https://disk.yandex.ru/d/N_kOjZnU6cu5FQ" target="_blank" rel="noopener noreferrer">
                    <div><img src={Folim} alt="Ozdoken" style={{ height: "100px" }} /><span>&nbsp;&nbsp;&nbsp;</span></div><div><h2>Документы</h2></div>
                    </a>
                </div>
                <br />
                <div>
                    <a className="navbar-item text-center background-dark-gray-ozdo" activeClassName="callout" href="https://disk.yandex.ru/d/HBZVDxtDIcmlqg" target="_blank" rel="noopener noreferrer">
                    <div><img src={Folim} alt="Ozdoken" style={{ height: "100px" }} /><span>&nbsp;&nbsp;&nbsp;</span></div><div><h2>Каталоги сельхозтехники</h2></div>
                    </a>
                </div>
                <br />
                <div>
                    <a className="navbar-item text-center background-dark-gray-ozdo" activeClassName="callout" href="https://disk.yandex.ru/d/w5PJ9nFFIZvwIg" target="_blank" rel="noopener noreferrer">
                    <div><img src={Folim} alt="Ozdoken" style={{ height: "100px" }} /><span>&nbsp;&nbsp;&nbsp;</span></div><div><h2>Каталоги запасных частей</h2></div>
                    </a>
                </div>
                <br />
                <div>
                    <a className="navbar-item text-center background-dark-gray-ozdo" activeClassName="callout" href="https://disk.yandex.ru/d/yvf6ZSYPKdhgIA" target="_blank" rel="noopener noreferrer">
                    <div><img src={Folim} alt="Ozdoken" style={{ height: "100px" }} /><span>&nbsp;&nbsp;&nbsp;</span></div><div><h2>Инструкции</h2></div>
                    </a>
                </div>
                <br />
                <div>
                    <a className="navbar-item text-center background-dark-gray-ozdo" activeClassName="callout" href="https://disk.yandex.ru/d/bqrYRKmeJaTH4w" target="_blank" rel="noopener noreferrer">
                    <div><img src={Folim} alt="Ozdoken" style={{ height: "100px" }} /><span>&nbsp;&nbsp;&nbsp;</span></div><div><h2>Фото</h2></div>
                    </a>
                </div>
                <br />
                <div>
                    <a className="navbar-item text-center background-dark-gray-ozdo" activeClassName="callout" href="https://disk.yandex.ru/d/zaG_5K-vEggAFg" target="_blank" rel="noopener noreferrer">
                    <div><img src={Folim} alt="Ozdoken" style={{ height: "100px" }} /><span>&nbsp;&nbsp;&nbsp;</span></div><div><h2>Видео</h2></div>
                    </a>
                    <br />
                </div>
            </div>
            <br /><br /><br /><br /><br /><br />
        </Container>
    </Layout>
)

export default PartsPage
